import axios from 'axios'
// 默认请求baseurl
// const ConfigBaseURL =  process.env.NODE_ENV === 'production' ? 'https://h5test.xingkech5.com/ici_cms' : 'https://h5test.xingkech5.com/ici_cms'
const ConfigBaseURL = "/"

//使用create方法创建axios实例
const Request = axios.create({
  timeout: 10000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: 'post', // 默认请求方式
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
})

// 添加请求拦截噐
Request.interceptors.request.use(config => {
  return config
})
// 添加响应拦截噐
Request.interceptors.response.use(response => {
  // 返回响应数据
  return response.data
}, error => {
  // 打印错误信息
  console.log('TCL: error', error)
  return Promise.reject(error)
})

export default Request