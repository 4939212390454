import Request from "./request";
import qs from 'qs'

// 获取文章 type	1项目作品  2关于我们  3奖项荣誉  4联系我们
export function __getarticle(data) {
  return Request({
    url: 'ajax/ajax_getarticle.php',
    data: qs.stringify(data)
  })
}

// 获取对应banner 1为顶部banner  2为 poject banner
export function __getonebanner(data) {
  return Request({
    url: 'ajax/ajax_getonebanner.php',
    data: qs.stringify(data)
  })
}

export function __getarticledetails(data) {
  return Request({
    url: 'ajax/ajax_getarticledetails.php',
    data: qs.stringify(data)
  })
}