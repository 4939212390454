<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
#app {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 1);
  /* background-image: url('~@/assets/images/menu-bg-01.png');
  background-size: 100% 100%;
  background-repeat: no-repeat; */
}
.fade-banner-enter-active,
.fade-banner-leave-active {
  transition: all 2s;
}
.fade-banner-enter,
.fade-banner-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.8s;
}
.fade2-enter,
.fade2-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.8s;
}
.slide-fade-leave-active {
  transition: all 0.8s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(30px);
  opacity: 0;
}

.line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
}

.line-2 {
  display: -webkit-box;
  box-orient: vertical;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.line-3 {
  display: -webkit-box;
  box-orient: vertical;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.line-4 {
  display: -webkit-box;
  box-orient: vertical;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.line-5 {
  display: -webkit-box;
  box-orient: vertical;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.box {
  height: 90px;
}
/* @media only screen and (max-width: 1024px) {
  .box {
    height: 100px;
  }
}
@media only screen and (max-width: 750px) {
  .box {
    height: 100px;
  }
} */
</style>