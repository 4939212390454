import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "home" */ '@/views/home'),
    children: [{
      path: 'project',
      name: 'project',
      component: () => import( /* webpackChunkName: "home" */ '@/views/project'),
    },{
      path: 'about',
      name: 'about',
      component: () => import( /* webpackChunkName: "home" */ '@/views/about'),
    },{
      path: 'award',
      name: 'award',
      component: () => import( /* webpackChunkName: "home" */ '@/views/award'),
    },{
      path: 'contact',
      name: 'contact',
      component: () => import( /* webpackChunkName: "home" */ '@/views/contact'),
    }]
  },
]
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes
})

export default router