function getWindowConfig () {
  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  if (typeof windowWidth !== 'number') {
    if (document.compatMode === 'CSS1Compat') {
      windowWidth = document.documentElement.clientWidth;
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowWidth = document.body.clientWidth;
      windowHeight = window.body.clientHeight;
    }
  }
  return {
    windowWidth: windowWidth,
    windowHeight: windowHeight
  }
}

// window.onresize=function(){
//   getWindowConfig()
// };

export default getWindowConfig