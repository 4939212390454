import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style/normalize.css'
import './assets/style/style.css'

// 网络请求
import { __getarticle, __getonebanner, __getarticledetails } from '@/http/index.js'
Vue.prototype.__getarticle = __getarticle
Vue.prototype.__getonebanner = __getonebanner
Vue.prototype.__getarticledetails = __getarticledetails
Vue.config.productionTip = false

// 图片懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  error: require('./assets/images/loading.png'),
  loading: require('./assets/images/loading.png'),
})

// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: '9tGGfFOfj0166Gx8QD2nBDDV8Zk6cmIE'
});

// 视窗大小
import getWindowConfig from './utils/getWindowConfig'
Vue.prototype.$windowConfig = getWindowConfig

// 事件总线
Vue.prototype.$eventBus = new Vue()
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
